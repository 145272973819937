import React from 'react'
import useWebsiteId from '@hooks/useWebsiteId'
import useWebsiteRoleStoresIds from '@hooks/useWebsiteRoleStoresIds'
import Select from '@packages/justo-parts/lib/components/fields/Select'
import {useApolloQuery as useQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

export default function Stores(props) {
  const websiteId = useWebsiteId()
  const {stores, loading} = useQuery({
    query: gql`
      query getStoresStoresField($websiteId: ID) {
        stores(websiteId: $websiteId) {
          items {
            value: _id
            label: name
          }
        }
      }
    `,
    variables: {websiteId},
    partial: true
  })
  const userStoresIds = useWebsiteRoleStoresIds()
  if (stores && props.filterByRole && userStoresIds) {
    stores.items = stores.items.filter(store => {
      return userStoresIds.includes(store.value)
    })
  }

  return (
    <div className={props.containerClassName}>
      <Select
        multi={props.multi !== false}
        {...props}
        {...props.passProps}
        options={stores ? stores.items : []}
        isLoading={loading}
        placeholder={props.placeholder || 'Selecciona un local'}
      />
    </div>
  )
}
