import React from 'react'
import dayjs from '@helpers/dayjs'
import Select from '@packages/justo-parts/lib/components/fields/Select'
import range from 'lodash/range'
import {Field,Form} from 'simple-react-form'

import styles from './styles.module.css'

export default function DateTime(props) {
  const {startDayAt = 11, endDayAt = 23} = props

  const onChange = function({date, time}) {
    const instance = dayjs()
      .startOf('day')
      .add(date, 'days')
      .add(time * 30, 'minutes')
    props.onChange(instance.toDate())
  }
  const dates = range(14).map(index => {
    const date = dayjs()
      .startOf('day')
      .add(index, 'day')
    return {label: date.format('LL'), value: `${index}`}
  })
  const times = range(24 * 2)
    .filter(index => {
      return index >= startDayAt * 2 && index < endDayAt * 2
    })
    .map(index => {
      const date = dayjs()
        .startOf('day')
        .add(index * 30, 'minutes')
      return {label: date.format('hh:mm a'), value: `${index}`}
    })

  const value = {
    date: String(dayjs(props.value).diff(dayjs().startOf('day'), 'day')),
    time: String(dayjs(props.value).diff(dayjs(props.value).startOf('day'), 'minutes') / 30)
  }
  return (
    <div className={styles.container}>
      <div className="label">{props.label}</div>
      <Form state={value} onChange={onChange} useFormTag={false}>
        <div className="row">
          <div className="col-xs-6">
            <Field fieldName="date" type={Select} options={dates} placeholder="Día" />
          </div>
          <div className="col-xs-6">
            <Field fieldName="time" type={Select} options={times} placeholder="Hora" />
          </div>
        </div>
      </Form>
      <div className="description">{props.description}</div>
      <div className="os-input-error">{props.errorMessage}</div>
    </div>
  )
}
