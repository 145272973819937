import React from 'react'
import autobind from 'autobind-decorator'
import range from 'lodash/range'
import PropTypes from 'prop-types'

import styles from './styles.module.css'

export default class SixDigitInput extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    autoFocus: PropTypes.bool,
    onReady: PropTypes.func
  }

  static defaultProps = {
    autoFocus: true,
    onReady: () => {}
  }

  constructor(props) {
    super(props)
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputsQty' does not exist on type 'SixDi... Remove this comment to see the full error message
    this.inputsQty = 6
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputsRefs' does not exist on type 'SixD... Remove this comment to see the full error message
    this.inputsRefs = {}
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputsQty' does not exist on type 'SixDi... Remove this comment to see the full error message
    range(this.inputsQty).forEach(index => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputsRefs' does not exist on type 'SixD... Remove this comment to see the full error message
      this.inputsRefs[`input_${index}`] = React.createRef()
    })
  }

  componentDidMount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'autoFocus' does not exist on type 'Reado... Remove this comment to see the full error message
    if (this.props.autoFocus) {
      this.focus()
    }
  }

  @autobind
  focus() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
    if (this.props.value) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.onChange('')
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputsRefs' does not exist on type 'SixD... Remove this comment to see the full error message
    this.inputsRefs[`input_0`].current.focus()
  }

  getNextIndex(value) {
    if (!value) return 0
    if (value.length > 5) return 0
    return value.length
  }

  @autobind
  focusNext() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const value = this.props.value
    const index = this.getNextIndex(value)
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputsRefs' does not exist on type 'SixD... Remove this comment to see the full error message
    this.inputsRefs[`input_${index}`].current.focus()
  }

  isReady() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
    return (this.props.value || '').length === 6
  }

  onChange(event, index) {
    const value = event.target.value || ''
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const oldValue = this.props.value || ''
    const newValue = oldValue + value

    if (newValue.length > 6) {
      const last = newValue[newValue.length - 1]
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.onChange(last)
    } else {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.onChange(newValue)
    }
    setTimeout(() => {
      if (this.isReady()) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputsRefs' does not exist on type 'SixD... Remove this comment to see the full error message
        this.inputsRefs[`input_${index}`].current.blur()
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onReady' does not exist on type 'Readonl... Remove this comment to see the full error message
        setTimeout(() => this.props.onReady(newValue), 200)
      } else {
        this.focusNext()
      }
    }, 1)
  }

  onKeyDown(event, index) {
    if (event.key === 'Backspace') {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
      const newVal = (this.props.value || '1').slice(0, -1)
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.onChange(newVal)
      setTimeout(this.focusNext, 1)
    }
  }

  renderInputs() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputsQty' does not exist on type 'SixDi... Remove this comment to see the full error message
    return range(this.inputsQty).map(index => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
      const value = (this.props.value || '')[index] || ''
      return (
        <input
          key={index}
          value={value}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputsRefs' does not exist on type 'SixD... Remove this comment to see the full error message
          ref={this.inputsRefs[`input_${index}`]}
          className={styles.input}
          placeholder="•"
          onChange={event => this.onChange(event, index)}
          onKeyDown={event => this.onKeyDown(event, index)}
          type="tel"
        />
      )
    })
  }

  render() {
    const className = this.isReady() ? styles.ready : styles.container
    return (
      <div className={className} onClick={this.focus}>
        {this.renderInputs()}
      </div>
    )
  }
}
