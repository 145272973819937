import React from 'react'
import autobind from 'autobind-decorator'
import clone from 'lodash/clone'
import PropTypes from 'prop-types'

import AddFile from './AddFile'
import File from './File'

import styles from './styles.module.css'

export default class FileInput extends React.Component {
  static propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    openManager: PropTypes.func,
    multi: PropTypes.bool,
    errorMessage: PropTypes.node,
    label: PropTypes.node,
    description: PropTypes.node,
    accept: PropTypes.string
  }

  @autobind
  addFile(fileId) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'multi' does not exist on type 'Readonly<... Remove this comment to see the full error message
    if (this.props.multi) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
      const value = clone(this.props.value || [])
      value.push({_id: fileId})
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.onChange(value)
    } else {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.onChange({_id: fileId})
    }
  }

  deleteFile(fileId) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
    this.props.onChange(this.props.value.filter(file => file._id !== fileId))
  }

  renderValue() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'multi' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const {multi, value} = this.props
    if (!value) return
    if (multi) {
      return value.map(item => (
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        <File key={item} multi fileId={item._id} clear={() => this.deleteFile(item._id)} />
      ))
    } else {
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      return <File fileId={value._id} clear={() => this.props.onChange(null)} />
    }
  }

  renderAddMoreFile() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'multi' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const {multi, accept} = this.props
    if (!multi) return
    return [
      <div key="sep" className={styles.addSeparator} />,
      <AddFile key="add" multi addFile={this.addFile} accept={accept} />
    ]
  }

  renderNoValue() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const {value, multi, accept} = this.props
    if (multi) return
    if (value) return
    return <AddFile addFile={this.addFile} accept={accept} />
  }

  render() {
    return (
      <div className={styles.mainContiaber}>
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'label' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
        <div className="label">{this.props.label}</div>
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'onClick' does not exist on type 'FileInp... Remove this comment to see the full error message */}
        <div className={styles.container} onClick={this.onClick}>
          {this.renderNoValue()}
          {this.renderValue()}
          {this.renderAddMoreFile()}
        </div>
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'description' does not exist on type 'Rea... Remove this comment to see the full error message */}
        <div className="description">{this.props.description}</div>
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'errorMessage' does not exist on type 'Re... Remove this comment to see the full error message */}
        <div className="error">{this.props.errorMessage}</div>
      </div>
    )
  }
}
