import React from 'react'
import {MdInsertInvitation as InsertInvitationIcon} from 'react-icons/md'
import SpinnerLoading from '@components/V3/loadingIndicators/Spinner'
import dayjs from '@helpers/dayjs'
import useState from '@hooks/useState'
import es from 'date-fns/locale/es'

import 'react-datepicker/dist/react-datepicker.css'
import styles from './styles.module.css'

let OriginalDatePicker = props => <div {...props}></div>
let datePickerLoaded = false

export default function DatePicker({
  label,
  startDate: propStartDate = dayjs(),
  endDate: propEndDate = dayjs(),
  onChange,
  message,
  extraProps = {},
  isRange = false,
  dateFormat = 'dd/MMM/yyyy'
}) {
  const [internalStart, setInternalStart] = useState(propStartDate)
  const [internalEnd, setInternalEnd] = useState(propEndDate)
  const [isOpen, setIsOpen] = useState(false)
  const [isChunkLoading, setIsChunkLoading] = useState(datePickerLoaded)

  const clickPicker = async e => {
    e.preventDefault()
    if (!datePickerLoaded) {
      setIsChunkLoading(true)
      OriginalDatePicker = (await import('react-datepicker')).default
      setIsChunkLoading(false)
      datePickerLoaded = true
    }
    setIsOpen(true)
  }

  const close = () => {
    setTimeout(() => setIsOpen(false), 50)
  }

  const resetEnd = () => {
    setInternalEnd(null)
  }

  const handleChange = dates => {
    if (isRange) {
      const startDate = dayjs(dates[0])
      const endDate = dates[1] ? dayjs(dates[1]) : null
      setInternalStart(startDate)
      setInternalEnd(endDate)
      if (startDate && endDate) onChange([startDate, endDate], {resetEnd, close})
    } else {
      setInternalStart(dayjs(dates))
      onChange(dayjs(dates), close)
    }
  }

  const handleClickOutside = () => {
    if (isRange) {
      if (
        (internalStart && !internalEnd) ||
        (internalStart.diff(propStartDate, 'days') === 0 &&
          internalEnd.diff(propEndDate, 'days') === 0)
      ) {
        setInternalStart(propStartDate)
        setInternalEnd(propEndDate)
      }
    }
    close()
  }

  return (
    <div className={styles.container}>
      <div className={styles.label}>{label}</div>
      <div
        className={styles.datePickerContainer}
        onClick={clickPicker}
        style={isOpen ? {border: '2px solid #2684FF', padding: 0} : {}}>
        <OriginalDatePicker
          open={isOpen}
          className={styles.datePicker}
          selected={internalStart.toDate()}
          startDate={internalStart.toDate()}
          endDate={internalEnd ? internalEnd.toDate() : null}
          onChange={handleChange}
          selectsRange={isRange}
          dateFormat={dateFormat}
          closeOnScroll={true}
          onClickOutside={handleClickOutside}
          locale={es}
          disabledKeyboardNavigation
          {...extraProps}>
          {isChunkLoading ? <SpinnerLoading /> : null}
        </OriginalDatePicker>
        <span className={styles.separator} />
        <InsertInvitationIcon
          size={38}
          className={styles.icon}
          style={isOpen ? {color: 'rgb(102, 102, 102)'} : {}}
        />
      </div>
    </div>
  )
}
