import useIsOutOfBounds from '@components/DeliveryWizard/internals/hooks/useIsOutOfBounds'
import useCartPreferences from '@page-components/Order/Cart/hooks/useCart.main'
import {useAddressLabel} from '@page-components/Order/DeliveryHeaderMobile/AddressLabel.main'
import {useTimeLabel} from '@page-components/Order/DeliveryHeaderMobile/TimeLabel.main'
import {FaMotorcycle as DeliveryIcon} from 'react-icons/fa'
import {
  MdLocationOn as ChooseDeliveryIcon,
  MdOutlineShoppingBag as GoIcon,
  MdWarningAmber,
  MdRestaurant as ServeIcon,
} from 'react-icons/md'

function AddressLabelInner() {
  const label = useAddressLabel()
  return <span className="text-xs font-semibold">{label}</span>
}
function TimeLabelInner() {
  const label = useTimeLabel()
  return <span className="flex text-xs font-normal italic">{label}</span>
}

type DeliveryTypeIconProps = {
  deliveryType: string
}

export function DeliveryTypeIcon({deliveryType}: DeliveryTypeIconProps) {
  switch (deliveryType) {
    case 'go':
      return <GoIcon className="h-6 w-6" />
    case 'serve':
      return <ServeIcon className="h-6 w-6" />
    case 'delivery':
      return <DeliveryIcon className="h-6 w-6" />
    default:
      return <ChooseDeliveryIcon className="h-6 w-6" />
  }
}

export default function AddressAndTimeLabel({hideTime = false}) {
  const isOutOfBounds = useIsOutOfBounds()
  const preferences = useCartPreferences()

  return (
    <div className="flex items-center gap-2 px-2 truncate">
      <div className="flex items-center">
        {isOutOfBounds ? (
          <MdWarningAmber className="text-orange-200 h-5 w-5 inline-block mr-1" />
        ) : (
          <DeliveryTypeIcon deliveryType={preferences.deliveryType} />
        )}
      </div>
      <div className="flex flex-col">
        <AddressLabelInner />
        {isOutOfBounds || hideTime ? null : <TimeLabelInner />}
      </div>
    </div>
  )
}
