import React from 'react'
import {MdDateRange as DateIcon} from 'react-icons/md'
import Button from '@components/Button'
import dayjs from '@helpers/dayjs'
import useState from '@hooks/useState'
import DateText from '@packages/justo-parts/lib/components/fields/DateText'
import Text from '@packages/justo-parts/lib/components/fields/Text'
import isNull from 'lodash/isNull'

import styles from './styles.module.css'

const TIME_FORMAT = 'HH:mm:ss'
const DATE_FORMAT = 'DD-MM-YYYY'

export default function DateTime(props) {
  const [hourState, setHourState] = useState(null)
  const timeFormat = props.timeFormat ? props.timeFormat : TIME_FORMAT
  const dateFormat = props.dateFormat ? props.dateFormat : DATE_FORMAT
  const hourValue = isNull(hourState)
    ? props.value
      ? dayjs(props.value).format(timeFormat)
      : ''
    : hourState

  const checkHour = () => {
    const parsed = dayjs(hourState, timeFormat)
    if (!parsed.isValid()) {
      setHourState(null)
      return
    }

    const finalDate = `${dayjs(props.value).format(dateFormat)} ${parsed.format(timeFormat)}`
    const date = dayjs(finalDate, dateFormat + ' ' + timeFormat)

    props.onChange(date.toDate())
    setHourState(null)
  }

  return (
    <div>
      <div className="label">{props.label}</div>
      <div className={styles.inputs}>
        <div className="row">
          <div className="col-xs-12 col-sm-5">
            <DateText value={props.value} onChange={props.onChange} />
          </div>
          <div className="col-xs-12 col-sm-5">
            {props.value ? (
              <Text
                value={hourValue}
                onChange={setHourState}
                passProps={{onBlur: checkHour}}
                onEnter={checkHour}
              />
            ) : null}
          </div>
          {props.hideCalendar ? null : (
            <div className="col-xs-12 col-sm-2">
              <Button
                icon={DateIcon}
                onClick={() =>
                  props.onChange(dayjs().startOf('week').add(1, 'week').add(12, 'hours').toDate())
                }>
                Lunes
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="description">
        {props.value ? dayjs(props.value).format('LLL') + '. ' : ''}
        {props.description}
      </div>
      <div className="os-input-error">{props.errorMessage}</div>
    </div>
  )
}
