import React, {Suspense} from 'react'
import {TimeLabelPrefsQuery, TimeLabelPrefsQueryVariables} from '@data/__generated__/types.main'
import {useUserPreferences} from '@data/queries/preferences/userPreferences.main'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'
import {useTranslation} from 'next-i18next'

export function useTimeLabel() {
  const websiteId = useWebsiteId()
  const data = useUserPreferences()

  const {preferences} = useApolloQuery<TimeLabelPrefsQuery, TimeLabelPrefsQueryVariables>({
    query: gql`
      query timeLabelPrefs($websiteId: ID) {
        preferences: userPreferences(websiteId: $websiteId) {
          _id
          timeLabel
        }
      }
    `,
    variables: {websiteId},
    fetchPolicy: 'cache-first'
  })

  const {t} = useTranslation('website', {keyPrefix: 'schedule'})

  if (data?.time === 'now') {
    return t('asSoonAsPossible')
  }

  return preferences?.timeLabel
}

function TimeLabelInner() {
  const label = useTimeLabel()

  return <span className="whitespace-nowrap text-ellipsis overflow-hidden">{label}</span>
}

export default function TimeLabel() {
  return (
    <Suspense fallback={null}>
      <TimeLabelInner />
    </Suspense>
  )
}
