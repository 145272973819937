import React from 'react'
import dynamic from 'next/dynamic'

import {getField} from './AutoForm'

// Dynamically imported component
const InternalAutoForm = dynamic(import('./AutoForm'), {
  ssr: false
})
export interface WrappedAutoFormProps {
  forwardedRef: React.ForwardedRef<any>
}

const WrappedAutoForm = React.forwardRef<any, any>((props, ref) => (
  <InternalAutoForm {...props} forwardedRef={ref} />
))

WrappedAutoForm.displayName = 'WrappedAutoForm'

const AutoForm: typeof WrappedAutoForm & {
  getFieldComponent?: (string) => any
  getField?: (string) => any
} = WrappedAutoForm

AutoForm.getFieldComponent = getField
AutoForm.getField = getField

export default AutoForm
