import React from 'react'
import WebsiteIdContext from '@providers/WebsiteIdContext'

export default function createWithWebsiteId(ComposedComponent): any {
  return function WithWebsiteId(props) {
    return (
      <WebsiteIdContext.Consumer>
        {contextWebsiteId => {
          return <ComposedComponent {...props} websiteId={contextWebsiteId} />
        }}
      </WebsiteIdContext.Consumer>
    )
  }
}
