import {
  GetCartPreferencesQuery,
  GetCartPreferencesQueryVariables
} from '@data/__generated__/types.main'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

export default function useCartPreferences() {
  const websiteId = useWebsiteId()

  const {preferences} = useApolloQuery<GetCartPreferencesQuery, GetCartPreferencesQueryVariables>({
    query: gql`
      query getCartPreferences($websiteId: ID!) {
        preferences: userPreferences(websiteId: $websiteId) {
          _id
          menuId
          tableName
          deliveryType
          storeId
          cart {
            itemsPrice
            totalPrice
            realPrice: itemsPrice(includeDiscount: false)
            subtotal: itemsPrice(
              includeDiscount: true
              includeCoupon: true
              includeJustoCoins: false
              includeLoyaltyDiscount: false
              includeWebsiteCoins: false
            )
            items {
              _id
              amount
            }
          }
        }
      }
    `,
    variables: {websiteId},
    fetchPolicy: 'cache-and-network'
  })

  return preferences
}
