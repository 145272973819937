import React from 'react'
import {MdClose as CloseIcon} from 'react-icons/md'
import withGraphQL from '@packages/react-apollo-decorators/lib/withGraphQL'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'

import styles from './styles.module.css'

@withGraphQL(
  gql`
    query getFileInfoFileField($fileId: ID) {
      file: fileManagerFile(fileId: $fileId) {
        name
        genericType
      }
    }
  `,
  {loading: null}
)
export default class File extends React.Component {
  static propTypes = {
    file: PropTypes.object,
    clear: PropTypes.func,
    loading: PropTypes.bool,
    multi: PropTypes.bool
  }

  renderLoading() {
    return <div className={styles.loading}>Cargando...</div>
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'file' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const {file, multi} = this.props
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message
    if (this.props.loading) return this.renderLoading()
    if (!file) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'clear' does not exist on type 'Readonly<... Remove this comment to see the full error message
      this.props.clear()
      return null
    }
    return (
      <div className={multi ? styles.multi : styles.container}>
        <div className={styles.name}>{file.name}</div>
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'clear' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
        <div className={styles.delete} onClick={this.props.clear}>
          <CloseIcon />
        </div>
      </div>
    )
  }
}
