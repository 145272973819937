import React from 'react'
import withWebsiteId from '@helpers/websites/withWebsiteId'
import Select from '@packages/justo-parts/lib/components/fields/Select'
import withGraphQL from '@packages/react-apollo-decorators/lib/withGraphQL'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'

@withWebsiteId
@withGraphQL(gql`
  query getStoresStoreField($websiteId: ID) {
    stores(websiteId: $websiteId) {
      items {
        value: _id
        label: name
        acceptServe
      }
    }
  }
`)
export default class Store extends React.Component {
  static propTypes = {
    passProps: PropTypes.object,
    stores: PropTypes.object,
    acceptServe: PropTypes.bool,
    validStoresIds: PropTypes.arrayOf(PropTypes.string)
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'stores' does not exist on type 'Readonly... Remove this comment to see the full error message
    const {stores, acceptServe, validStoresIds} = this.props
    if (!stores) return null

    const options = stores.items.filter(store => {
      if (validStoresIds && !validStoresIds.includes(store.value)) return false
      if (!acceptServe) return true
      return store.acceptServe
    })

    return (
      <Select
        {...this.props}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'passProps' does not exist on type 'Reado... Remove this comment to see the full error message
        {...this.props.passProps}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'passProps' does not exist on type 'Reado... Remove this comment to see the full error message
        passProps={this.props.passProps}
        options={options}
      />
    )
  }
}
