import React from 'react'
import useEffect from '@hooks/useEffect'
import useState from '@hooks/useState'
import validateID from '@i18n/helpers/validateID'
import Select from '@packages/justo-parts/lib/components/fields/Select'
import Text from '@packages/justo-parts/lib/components/fields/Text'
import get from 'lodash/get'
import {Field, Form} from 'simple-react-form'

import styles from './styles.module.css'

export default function ReceiverDataCO(props) {
  const [id, setId] = useState(0)
  const [idType, setIdType] = useState('')

  const handlerStates = () => {
    if (!props.value) return
    setId(props.value.identification || '')
    setIdType(props.value.identifierType || '')
    if (props.value.responsibilities && props.value.responsibilities.length === 0) {
      props.onChange({
        ...props.value,
        responsibilities: null
      })
    }
    if (props.value.digitCheck !== '' && props.value.identifierType === '22') {
      props.onChange({
        ...props.value,
        digitCheck: ''
      })
    }
  }

  useEffect(() => {
    handlerStates()
  }, [props.value])

  useEffect(() => {
    if (!props.value) return () => {}
    if (!props.value.identification) {
      props.onChange({
        ...props.value,
        digitCheck: ''
      })
    } else {
      const [identification, digitCheck] = validateID['CO'](props.value.identification || [])
      props.onChange({
        ...props.value,
        identification,
        digitCheck
      })
    }
  }, [id, idType])

  const schema = get(props, 'passProps.data.type')

  const {
    personType: {
      fieldOptions: {options: personTypeOptions}
    },
    identifierType: {
      fieldOptions: {options: identifierTypeOptions}
    },
    regimeType: {
      fieldOptions: {options: regimeTypeOptions}
    },
    responsibilities: {
      fieldOptions: {options: responsibilitiesOptions}
    },
    address: {
      type: {
        billingCityCode: {
          fieldOptions: {options: billingCityCodeOptions}
        },
        billingRegionCode: {
          fieldOptions: {options: billingRegionCodeOptions}
        },
        billingCountryCode: {
          fieldOptions: {options: billingCountryCodeOptions}
        }
      }
    }
  } = schema

  const showBillingRegionCode = get(props, 'value.address.billingCountryCode')
  const showBillingCityCode = showBillingRegionCode && get(props, 'value.address.billingRegionCode')
  const showBillingAddress = showBillingCityCode && get(props, 'value.address.billingCityCode')

  return (
    <div className={styles.container}>
      <Form state={props.value} onChange={props.onChange} useFormTag={false}>
        <Field label="Tipo" fieldName="personType" type={Select} options={personTypeOptions} />

        <Field
          label="Tipo de identificación"
          fieldName="identifierType"
          type={Select}
          options={identifierTypeOptions}
        />
        <div className={styles.idContainer}>
          <div className={styles.idContainer__id}>
            <Field
              label="Identificación"
              placeholder="CC/NIT"
              fieldName="identification"
              type={Text}
            />
          </div>
          <div className={styles.idContainer__dv}>
            <Field label="DV" fieldName="digitCheck" type={Text} disabled />
          </div>
        </div>

        {get(props, 'value.personType') === '2' ? (
          <>
            <Field label="Nombres" fieldName="firstName" type={Text} />
            <Field label="Apellidos" fieldName="lastName" type={Text} />
          </>
        ) : null}
        <Field label="Razón social" fieldName="companyName" type={Text} />
        <Field label="Email" fieldName="email" type={Text} fieldType="email" />
        <Field
          label="Tipo de régimen"
          fieldName="regimeType"
          type={Select}
          options={regimeTypeOptions}
        />
        <Field
          label="Responsabilidad fiscal"
          fieldName="responsibilities"
          type={Select}
          multi
          options={responsibilitiesOptions}
        />
        <Field
          label="País"
          fieldName="address.billingCountryCode"
          type={Select}
          options={billingCountryCodeOptions}
        />
        {showBillingRegionCode ? (
          <Field
            label="Región"
            fieldName="address.billingRegionCode"
            type={Select}
            options={billingRegionCodeOptions}
          />
        ) : null}
        {showBillingCityCode ? (
          <Field
            label="Ciudad"
            fieldName="address.billingCityCode"
            type={Select}
            options={billingCityCodeOptions.filter(city =>
              city.value.startsWith(props.value.address.billingRegionCode)
            )}
          />
        ) : null}
        {showBillingAddress ? (
          <Field label="Direccion" fieldName="address.billingAddress" type={Text} />
        ) : null}
      </Form>
    </div>
  )
}
