import React from 'react'
import useEffect from '@hooks/useEffect'
import union from 'lodash/union'
import without from 'lodash/without'

import styles from './styles.module.css'

export default function MultipleCheckbox(props) {
  const {value, onChange, options, defaultChecked} = props

  useEffect(() => {
    if (value === undefined) onChange(defaultChecked)
  }, [])

  return (
    <div className={styles.container}>
      <div className="label">{props.label}</div>
      {options.map((option, index) => {
        const selected = (value || []).includes(option.value)
        const onClick = select => {
          if (select) {
            onChange(union(value || [], [option.value]))
          } else {
            onChange(without(value, option.value))
          }
        }
        return (
          <label key={index} className={styles.item}>
            <input
              type="checkbox"
              className={styles.input}
              checked={selected}
              onChange={() => onClick(!selected)}
            />
            <span className={styles.label}>{option.label}</span>
          </label>
        )
      })}
      <div className="description">{props.description}</div>
      <div className="os-input-error">{props.errorMessage}</div>
    </div>
  )
}
