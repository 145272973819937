import React from 'react'
import useIsOutOfBounds from '@components/DeliveryWizard/internals/hooks/useIsOutOfBounds'
import SafeSuspense from '@components/SafeSuspense'
import {
  AddressLabelPreferencesQuery,
  AddressLabelPreferencesQueryVariables
} from '@data/__generated__/types.main'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'
import isEmpty from 'lodash/isEmpty'
import {useTranslation} from 'next-i18next'

export function useAddressLabel() {
  const websiteId = useWebsiteId()
  const {preferences} = useApolloQuery<
    AddressLabelPreferencesQuery,
    AddressLabelPreferencesQueryVariables
  >({
    query: gql`
      query addressLabelPreferences($websiteId: ID!) {
        preferences: userPreferences(websiteId: $websiteId) {
          _id
          deliveryType
          tableName
          store {
            _id
            name
          }
          address {
            _id
            address
            addressLine2
          }
        }
      }
    `,
    variables: {
      websiteId
    },
    fetchPolicy: 'cache-first'
  })
  const {t} = useTranslation('website', {keyPrefix: 'delivery.address'})
  const isOutOfBounds = useIsOutOfBounds()

  if (isOutOfBounds) {
    return t('outOfBounds')
  }

  if (preferences?.deliveryType === 'serve' && !isEmpty(preferences?.tableName)) {
    return t('serve', {tableName: preferences.tableName, storeName: preferences.store?.name})
  }

  if (preferences?.deliveryType === 'go' && preferences?.store?.name) {
    return t('go', {storeName: preferences.store.name})
  }

  if (preferences?.deliveryType === 'delivery' && preferences?.address?.address) {
    const address =
      (preferences.address?.addressLine2 ? preferences.address.addressLine2 + ', ' : '') +
      preferences.address.address
    return t('delivery', {address: address})
  }

  return t('empty')
}

function AddressLabelInner() {
  const label = useAddressLabel()

  return <span className="whitespace-nowrap text-ellipsis overflow-hidden">{label}</span>
}

export default function AddressLabel() {
  return (
    <SafeSuspense fallback={null}>
      <AddressLabelInner />
    </SafeSuspense>
  )
}
