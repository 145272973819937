import React from 'react'
import {MdAdd as AddIcon} from 'react-icons/md'
import withMessage from '@packages/justo-parts/lib/decorators/withMessage'
import withMutation from '@packages/react-apollo-decorators/lib/withMutation'
import autobind from 'autobind-decorator'
import gql from 'graphql-tag'
import mime from 'mime-types'
import PropTypes from 'prop-types'
import fetch from 'unfetch'

import styles from './styles.module.css'

@withMutation(gql`
  mutation generateUploadCredentialsAddFile($name: String, $size: Float, $type: String) {
    result: generateUploadCredentials(name: $name, size: $size, type: $type) {
      fileId
      url
      fields
      key
    }
  }
`)
@withMutation(gql`
  mutation completeUploadAddFile($fileId: ID) {
    completeUpload(fileId: $fileId) {
      _id
    }
  }
`)
@withMessage
export default class Upload extends React.Component {
  static propTypes = {
    showMessage: PropTypes.func,
    generateUploadCredentialsAddFile: PropTypes.func,
    completeUploadAddFile: PropTypes.func,
    addFile: PropTypes.func,
    multi: PropTypes.bool,
    accept: PropTypes.string
  }

  state = {loading: false}

  inputRef = React.createRef()

  @autobind
  async onChange(event) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'files' does not exist on type 'unknown'.
    const file = this.inputRef.current.files[0]
    this.setState({loading: true})
    try {
      const credentials = await this.requestCredentials(file)
      await this.uploadFile(credentials, file)
      await this.complete(credentials.fileId)
      this.setState({loading: false})
    } catch (error) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'showMessage' does not exist on type 'Rea... Remove this comment to see the full error message
      this.props.showMessage(error)
      this.setState({loading: false})
    }
  }

  @autobind
  async requestCredentials(file) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'generateUploadCredentialsAddFile' does not exis... Remove this comment to see the full error message
    const {result} = await this.props.generateUploadCredentialsAddFile({
      name: file.name.normalize(),
      size: file.size,
      type: file.type || mime.lookup(file.name) || 'application/octet-stream'
    })
    return result
  }

  async uploadFile({fields, key, url}, file) {
    const formData = new FormData()
    const data = {
      ...fields,
      key: key,
      file: file
    }

    for (const name in data) {
      formData.append(name, data[name])
    }

    await fetch(url, {
      method: 'POST',
      body: formData
    })
  }

  @autobind
  async complete(fileId) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'completeUploadAddFile' does not exist on type '... Remove this comment to see the full error message
    await this.props.completeUploadAddFile({fileId})
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'addFile' does not exist on type 'Readonl... Remove this comment to see the full error message
    this.props.addFile(fileId)
  }

  renderInput() {
    if (this.state.loading) return
    return (
      <div className={styles.add}>
        <label htmlFor="file-upload" className={styles.label}>
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'accept' does not exist on type 'Readonly... Remove this comment to see the full error message */}
          <AddIcon /> Agregar archivo {this.props.accept}
        </label>
        <input
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'RefObject<unknown>' is not assignable to typ... Remove this comment to see the full error message
          ref={this.inputRef}
          id="file-upload"
          type="file"
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'accept' does not exist on type 'Readonly... Remove this comment to see the full error message
          accept={this.props.accept || '*'}
          className={styles.input}
          onChange={this.onChange}
        />
      </div>
    )
  }

  renderLoading() {
    if (!this.state.loading) return
    return <div className={styles.loading}>Subiendo archivo...</div>
  }

  render() {
    return (
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'multi' does not exist on type 'Readonly<... Remove this comment to see the full error message
      <div className={this.props.multi ? styles.multi : styles.container}>
        {this.renderLoading()}
        {this.renderInput()}
      </div>
    )
  }
}
