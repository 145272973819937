const getDigitCheck = (legalId: string) => {
  const weights = [3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71]
  const legalIdValues = legalId
    .split('')
    .map(val => parseInt(val))
    .reverse()
  if (legalIdValues.length >= 15) return ''

  const sumProduct = legalIdValues.reduce((acc, val, index) => acc + val * weights[index], 0)
  if (isNaN(sumProduct)) return ''

  const result = sumProduct % 11
  return result > 1 ? `${11 - result}` : `${result}`
}

const getLegalIdParts = (legalId: string) => {
  const split = legalId.replace(/\./g, '').split('-')
  const base = split[0]
  return [base, getDigitCheck(base)]
}

export default getLegalIdParts
