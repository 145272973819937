import DeliveryWizard from '@components/DeliveryWizard'
import Pill from '@components/V3/Pill'
import useState from '@hooks/useState'
import AddressAndTimeLabel from '@page-components/Order/CartV3/CartContent/CartDelivery/AddressAndTimeLabel'

export default function Place(props) {
  const [open, setOpen] = useState(false)

  return (
    <div>
      <div className="label">{props.label}</div>
      <Pill
        showCaret={false}
        className="h-full w-full overflow-hidden"
        onClick={() => setOpen(true)}
      >
        <AddressAndTimeLabel hideTime />
      </Pill>
      <DeliveryWizard.AsModal
        actionLocation="order-delivery"
        isOpen={open}
        hideScheduleOptions
        initialStep="create"
        onChange={address => {
          props.onChange(address?.placeId)
          setOpen(false)
        }}
        onClose={() => setOpen(false)}
      />
      <div className="description">{props.description}</div>
    </div>
  )
}
