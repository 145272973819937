import React from 'react'
import useEffect from '@hooks/useEffect'
import useState from '@hooks/useState'

import getPrettyText from './getPrettyText'
import getTextFromNumber from './getTextFromNumber'
import getValueFromText from './getValueFromText'

export default function Input(props) {
  const {value, onChange, passProps, placeholder} = props
  const [textValue, setTextValue] = useState(getTextFromNumber(value))
  const [focused, setFocused] = useState<boolean>(false)

  useEffect(() => {
    if (focused) return

    const newTextValue = getTextFromNumber(value)
    if (textValue !== newTextValue) {
      setTextValue(newTextValue)
    }
  }, [value, focused])

  const updateValue = text => {
    const valueFromText = getValueFromText(text)
    onChange(valueFromText)
  }

  return (
    <input
      className="os-input-text"
      value={focused ? textValue : getPrettyText(value)}
      onChange={event => {
        setTextValue(event.target.value)
        updateValue(event.target.value)
      }}
      onFocus={() => {
        setFocused(true)
        if (passProps.onFocus) {
          passProps.onFocus()
        }
      }}
      onBlur={event => {
        setFocused(false)
        if (passProps.onBlur) {
          passProps.onBlur(event)
        }
      }}
      onKeyDown={event => {
        if (passProps.onKeyDown) {
          passProps.onKeyDown(event)
        }
      }}
      placeholder={placeholder}
    />
  )
}
