import React from 'react'
import {FaChevronDown, FaChevronUp} from 'react-icons/fa'
import useState from '@hooks/useState'

import styles from './styles.module.css'

export default function Item(props) {
  const {label, index, defaultShow = false, children} = props
  const [show, setShow] = useState(defaultShow)

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={() => setShow(!show)}>
        <div className={styles.label}>{label ? label : `#${index + 1}`}</div>
        <div>{show ? <FaChevronUp /> : <FaChevronDown />}</div>
      </div>
      {show ? <div className={styles.content}>{children}</div> : null}
    </div>
  )
}
