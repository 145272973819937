import React from 'react'

import Input from './Input'

import styles from './styles.module.css'

export default function Money(props) {
  const {label, description, errorMessage} = props
  return (
    <div className={styles.container}>
      <div className="label">{label}</div>
      <div className="os-input-container">
        <Input {...props} />
      </div>
      <div className="description">{description}</div>
      <div className="os-input-error">{errorMessage}</div>
    </div>
  )
}
