import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

import useWebsiteId from './useWebsiteId'

export default function useWebsiteRole() {
  const websiteId = useWebsiteId()

  const {me} = useApolloQuery({
    query: gql`
      query getMyWebsiteRole($websiteId: ID) {
        me {
          _id
          roleInWebsite(websiteId: $websiteId) {
            _id
            role
            storesIds
          }
        }
      }
    `,
    variables: {websiteId},
    fetchPolicy: 'cache-first'
  })

  if (!me || !me.roleInWebsite) return null
  return me.roleInWebsite
}
