import React from 'react'
import useCountryCode from '@hooks/useCountryCode'
import Text from '@packages/justo-parts/lib/components/fields/Text'
import omit from 'lodash/omit'

import getValue from './getValue'
import locales from './locales'

import styles from './styles.module.css'

export default function Phone(props) {
  const websiteCountryCode = props.parentValue ? props.parentValue.countryCode : null
  const countryCode = useCountryCode()
  const starter = locales[websiteCountryCode] || locales[countryCode] || locales.CL
  const val = getValue(props.value, starter)
  const onChange = text => {
    const numbers = text.replace(/\D/g, '')
    if (!numbers) {
      props.onChange(null)
    } else {
      props.onChange(starter + numbers)
    }
  }

  const passingProps = omit(props, 'errorMessage')
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.digit}>{starter}</div>
        <Text {...passingProps} value={val} onChange={onChange} autoComplete="tel-national" />
      </div>
      <div className="description">{props.description}</div>
      <div className="os-input-error">{props.errorMessage}</div>
    </div>
  )
}
