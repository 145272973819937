import React from 'react'
import {ApolloClient} from '@apollo/client'
import createAutoform from '@packages/orionjs-react-autoform'
import getClient from '@providers/apollo/getClient'
import {i18n} from 'next-i18next'

import fields from '../fields'

export const getField = fieldName => fields[fieldName]

export const OriginalAutoForm = createAutoform({
  getField,
  getDefaultLabel: () => 'Valor',
  // @ts-expect-error ApolloClient is not compatible with ApolloClient<any>
  getClient: () => getClient() as unknown as ApolloClient<any>,
  onError: error => {
    if (error.extra && error.extra.hash) {
      alert(`🔎 Ocurrió un error

        Si el error persiste, puedes enviar el siguiente código a soporte: ${error.extra.hash}
      `)
    } else {
      alert(error.message)
    }
  },
  getErrorText: (code, field: any) => {
    return i18n.t(`errors.${code}`, {label: field.label, ns: 'deprecated'})
  },
  loading: <div>Cargando...</div>
})

export default function InternalAutoForm(props) {
  return <OriginalAutoForm {...props} ref={props.forwardedRef} />
}
